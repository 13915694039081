import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl, FormGroup, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms'
import { trigger } from '@angular/animations'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatSelectModule } from '@angular/material/select'
import { CdkTextareaAutosize } from '@angular/cdk/text-field'
import { CurrencyPipe } from '@angular/common'
import { MatInput } from '@angular/material/input'
import { MatIcon } from '@angular/material/icon'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { SelectSnapshot } from '@ngxs-labs/select-snapshot'
import { MatSlideToggle } from '@angular/material/slide-toggle'
import { MatButtonModule, MatIconButton } from '@angular/material/button'
import moment from 'moment'
import { finalize, first, takeUntil } from 'rxjs/operators'

import { EconomyTabService } from '../../../../../core/api'
import { DateFormat } from '../../../../../core/enums/global/date-format'
import { PaidStatus } from '../enums/paid-status'
import { PaymentTypes } from '../../../../../core/enums/economy/payment-types'
import { UtilsService, ValidationService } from '../../../../../core/services'
import { BaseComponent } from '../../../../../shared/components/base/base.component'
import { SnackbarService } from '../../../../../shared/components/snackbar/snackbar.service'
import { DashboardState } from '../../../states/dashboard.state'
import { PriceCategoryResponse } from './interfaces/price-category-response'
import { PriceCategoryType } from '../enums/price-category-type'
import { Constants } from '../../../../../constants/constants'
import { OverlayAnimations } from '../../../../../shared/components/overlay/animations'
import { EconomyUserType } from '../enums/economy-user-type'
import { Organization } from '../../../../../core/interfaces/organization/organization'
import { PriceModule } from '../../../../../shared/modules/price/price.module'
import { UserPaymentResponse } from './interfaces/user-payment-response'
import { DisableValueChangeOnWheelDirective } from '../../../../../shared/directives/disable-value-change-on-wheel.directive'
import { OverlayModule } from '../../../../../shared/components/overlay/overlay.module'
import { ButtonComponent } from '../../../../../shared/components/button/button.component'
import { FadeInComponent } from '../../../../../shared/components/animations/fade-in.component'
import { InfoBlockComponent } from '../../../../../shared/components/info-block/info-block.component'
import { LoaderComponent } from '../../../../../shared/modules/loader/loader.component'
import { ExpandYComponent } from '../../../../../shared/components/animations/expand-y.component'
import { IsNumberPipe } from '../../../../../shared/pipes'
import { FloatingElementDirective } from '../../../../../shared/directives/floating-element.directive'
import { UserPaymentPayload } from './interfaces/user-payment-payload'

@Component({
  selector: 'app-edit-user-payment',
  templateUrl: './edit-user-payment.component.html',
  styleUrls: ['./edit-user-payment.component.scss'],
  host: { '[@editUserPaymentAnimation]': '' },
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    MatFormFieldModule,
    MatInput,
    MatIcon,
    MatButtonModule,
    MatIconButton,
    MatSelectModule,
    MatSlideToggle,
    CdkTextareaAutosize,
    OverlayModule,
    FadeInComponent,
    InfoBlockComponent,
    LoaderComponent,
    ExpandYComponent,
    ButtonComponent,
    PriceModule,
    CurrencyPipe,
    IsNumberPipe,
    FloatingElementDirective,
    DisableValueChangeOnWheelDirective
  ],
  animations: [trigger('editUserPaymentAnimation', OverlayAnimations.detailsPopup)]
})
export class EditUserPaymentComponent extends BaseComponent implements OnInit {
  @Input({ required: true }) userPaymentId: number

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>()

  @SelectSnapshot(DashboardState.organization) organization: Organization

  loading: boolean = false
  userPayment: UserPaymentResponse | undefined
  isLoadingPriceCategories: boolean = false
  priceCategories: PriceCategoryResponse[]
  customPriceCategory: PriceCategoryResponse | undefined
  customPrice: number
  isSettingCustomPrice: boolean = false
  minAge: number = Constants.minAge
  maxAge: number = Constants.maxAge
  userPaymentForm: FormGroup<{
    status: FormControl<PaidStatus>
    note: FormControl<string>
    amount?: FormControl<number>
    includeFee?: FormControl<boolean>
    publishDate?: FormControl<string>
    dueDate?: FormControl<string>
    chargeDate?: FormControl<string>
    customPrice?: FormControl<number | null>
  }>
  today = moment().format(DateFormat.YYYYMMDD)

  get PaidStatus() {
    return PaidStatus
  }

  get PaymentTypes() {
    return PaymentTypes
  }

  get EconomyUserType() {
    return EconomyUserType
  }

  constructor(
    private formBuilder: NonNullableFormBuilder,
    private translate: TranslateService,
    private economyTabService: EconomyTabService,
    private snackbarService: SnackbarService,
    private utils: UtilsService
  ) {
    super()
  }

  ngOnInit() {
    this.getUserPayment()
  }

  private getUserPayment(): void {
    this.loading = true
    this.economyTabService
      .getUserPayment(this.userPaymentId)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((response) => {
        this.userPayment = response
        this.initForm()

        if (this.userPayment.payment.payment_type === PaymentTypes.MembershipFee) {
          this.isLoadingPriceCategories = true
          this.economyTabService
            .getPriceCategories(this.userPaymentId)
            .pipe(finalize(() => (this.isLoadingPriceCategories = false)))
            .subscribe((categories) => {
              this.priceCategories = categories
                .filter((category) => category.type === PriceCategoryType.Default)
                .map((category) => ({
                  ...category,
                  net_amount: this.utils.getNOK(category.net_amount),
                  total_fee: this.utils.getNOK(category.total_fee),
                  gross_amount: this.utils.getNOK(category.gross_amount)
                }))

              this.customPriceCategory = categories.find((category) => category.type === PriceCategoryType.Custom)
              if (this.customPriceCategory) {
                this.toggleCustomPrice(true)
              }
            })
        }
      })
  }

  private initForm(): void {
    this.userPaymentForm = this.formBuilder.group({
      status: [
        {
          value: (this.userPayment!.paid_status === PaidStatus.Optional
            ? PaidStatus.NotPaid
            : this.userPayment!.paid_status) as PaidStatus,
          disabled: this.userPayment!.paid_status === PaidStatus.Declined
        },
        Validators.required
      ],
      note: [this.userPayment!.note]
    })

    if (this.userPayment!.payment.payment_type !== PaymentTypes.EventPayment) {
      this.userPaymentForm.addControl(
        'dueDate',
        new FormControl(
          {
            value: moment(this.userPayment!.due_date).format(DateFormat.YYYYMMDD),
            disabled: this.userPayment!.paid_status === PaidStatus.Declined
          },
          {
            nonNullable: true,
            validators: Validators.required
          }
        )
      )

      if (!this.userPayment!.on_hold) {
        this.userPaymentForm.addControl(
          'publishDate',
          new FormControl(
            {
              value: moment(this.userPayment!.publish_date).format(DateFormat.YYYYMMDD),
              disabled:
                moment(this.userPayment!.publish_date).isBefore(moment()) ||
                this.userPaymentForm.value.status !== PaidStatus.NotPaid
            },
            {
              nonNullable: true,
              validators: Validators.required
            }
          )
        )

        if (this.userPayment!.payment.publish_date) {
          this.userPaymentForm.controls.publishDate!.addValidators(
            ValidationService.minDate({
              minDate: moment(this.userPayment!.payment.publish_date).format(DateFormat.YYYYMMDD)
            })
          )
        }
      }
    }

    if (
      this.userPayment!.payment.payment_type === PaymentTypes.RegularPayment ||
      this.userPayment!.payment.payment_type === PaymentTypes.TrainingFee
    ) {
      this.userPaymentForm.addControl(
        'amount',
        new FormControl(
          {
            value: this.utils.getNOK(this.userPayment!.amount),
            disabled: this.userPayment!.paid_status === PaidStatus.Declined
          },
          { nonNullable: true, validators: Validators.required }
        )
      )

      this.userPaymentForm.addControl(
        'includeFee',
        new FormControl(
          {
            value: this.userPayment!.include_fee,
            disabled: this.userPayment!.paid_status === PaidStatus.Declined
          },
          { nonNullable: true }
        )
      )

      if (this.userPayment!.include_fee === null) {
        this.userPaymentForm.controls
          .amount!.valueChanges.pipe(first(), takeUntil(this.userPaymentForm.controls.includeFee!.valueChanges))
          .subscribe(() => this.userPaymentForm.patchValue({ includeFee: false }))
      }
    }

    this.userPaymentForm.controls.status.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((value: PaidStatus) => {
        if (value === PaidStatus.PaidManually) {
          this.userPaymentForm.addControl(
            'chargeDate',
            new FormControl(this.today, {
              nonNullable: true,
              validators: [Validators.required, ValidationService.maxDate({ maxDate: this.today })]
            })
          )
        } else {
          this.userPaymentForm.removeControl('chargeDate')
        }

        if (this.userPaymentForm.controls.publishDate) {
          if (value === PaidStatus.NotPaid && moment(this.userPayment!.publish_date).isAfter(moment())) {
            this.userPaymentForm.controls.publishDate.enable()
          } else {
            this.userPaymentForm.controls.publishDate.disable()
          }
        }

        if (this.userPayment!.payment.payment_type === PaymentTypes.MembershipFee && this.isSettingCustomPrice) {
          this.userPaymentForm.controls.customPrice![value === PaidStatus.NotPaid ? 'enable' : 'disable']()
          this.userPaymentForm.controls.includeFee![value === PaidStatus.NotPaid ? 'enable' : 'disable']()
        }
      })
  }

  toggleCustomPrice(isSettingCustomPrice: boolean): void {
    if (this.userPaymentForm.value.status === PaidStatus.NotPaid) {
      if (isSettingCustomPrice) {
        this.userPaymentForm.addControl(
          'customPrice',
          new FormControl(
            this.customPriceCategory ? this.utils.getNOK(this.customPriceCategory.amount) : null,
            Validators.required
          )
        )
        this.userPaymentForm.addControl(
          'includeFee',
          new FormControl(this.customPriceCategory ? this.customPriceCategory.include_fee : true, { nonNullable: true })
        )

        if (this.customPriceCategory?.include_fee === null) {
          this.userPaymentForm.controls
            .customPrice!.valueChanges.pipe(first(), takeUntil(this.userPaymentForm.controls.includeFee!.valueChanges))
            .subscribe(() => this.userPaymentForm.patchValue({ includeFee: false }))
        }
      } else {
        this.userPaymentForm.removeControl('customPrice')
        this.userPaymentForm.removeControl('includeFee')
      }

      this.isSettingCustomPrice = isSettingCustomPrice
    }
  }

  saveUserPayment(): void {
    if (this.userPaymentForm.valid) {
      this.loading = true

      const payload: UserPaymentPayload = {
        note: this.userPaymentForm.value.note!
      }

      if (this.userPaymentForm.controls.publishDate?.enabled) {
        payload.publish_date = this.userPaymentForm.value.publishDate
      }

      if (this.userPaymentForm.value.status) {
        payload.payment_status = this.userPaymentForm.value.status
      }

      if (this.userPayment!.payment.payment_type !== PaymentTypes.EventPayment && this.userPaymentForm.value.dueDate) {
        payload.due_date = moment(new Date(this.userPaymentForm.value.dueDate).setHours(23, 59)).format()
      }

      if (this.userPaymentForm.value.status === PaidStatus.PaidManually) {
        payload.charge_made = this.userPaymentForm.value.chargeDate
      }

      switch (this.userPayment!.payment.payment_type) {
        case PaymentTypes.MembershipFee:
          payload.custom_price_option = this.isSettingCustomPrice
            ? {
                amount: this.utils.getOre(this.userPaymentForm.controls.customPrice!.value),
                include_fee: this.userPaymentForm.value.includeFee!
              }
            : null
          break
        case PaymentTypes.RegularPayment:
        case PaymentTypes.TrainingFee:
          if (this.utils.isNumber(this.userPaymentForm.value.amount)) {
            payload.amount = this.utils.getOre(this.userPaymentForm.value.amount)
          }
          if (this.userPaymentForm.value.includeFee !== undefined) {
            payload.include_fee = this.userPaymentForm.value.includeFee
          }
      }

      this.economyTabService
        .updateUserPayment(this.userPaymentId, payload)
        .pipe(finalize(() => (this.loading = false)))
        .subscribe(() => {
          this.snackbarService.success(
            this.translate.instant('payment.payment_update_success', {
              name: `${this.userPayment!.user.first_name} ${this.userPayment!.user.last_name}`
            })
          )
          this.close.emit(true)
        })
    }
  }

  onClose(): void {
    this.close.emit(false)
  }
}
